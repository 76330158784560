import React from 'react'
import styles from './index.module.scss'
import Image from 'next/image';
import IconCheck from '@/assets/img/img-segmentation/check.png'

import IconExplorador from '@/assets/img/img-segmentation/icon-explorador.png'
import IconAprediz from '@/assets/img/img-segmentation/icon-aprendiz.png'
import IconProfession from '@/assets/img/img-segmentation/icon-profesional.png'
import IconMaestro from '@/assets/img/img-segmentation/icon-maestro.png'
import IconLeyenda from '@/assets/img/img-segmentation/icon-leyenda.png'

interface IBenefitsData {
    benefit: string;
    Explorador: boolean;
    Aprendiz: boolean;
    Profesional: boolean;
    Maestro: boolean;
    Leyenda: boolean;
}

const benefitsData: IBenefitsData[] = [
    { benefit: 'Obsequio primera venta', Explorador: true, Aprendiz: false, Profesional: false, Maestro: false, Leyenda: false },
    { benefit: 'Incentivo por monto mes', Explorador: true, Aprendiz: true, Profesional: false, Maestro: false, Leyenda: false },
    { benefit: 'Incentivo por continuidad', Explorador: false, Aprendiz: true, Profesional: true, Maestro: false, Leyenda: false },
    { benefit: 'Comisiones ganadas (10% y 20%)', Explorador: false, Aprendiz: false, Profesional: true, Maestro: true, Leyenda: false },
    { benefit: '25% de descuento en tu mes de cumpleaños', Explorador: false, Aprendiz: false, Profesional: false, Maestro: true, Leyenda: false },
    { benefit: 'Envío gratis por compras/ventas de: $250.000', Explorador: false, Aprendiz: false, Profesional: false, Maestro: false, Leyenda: true },
];

const IconLevels = [
    IconExplorador,
    IconAprediz,
    IconProfession,
    IconMaestro,
    IconLeyenda,
]

const BenefitsTableSegmentation: React.FC = () => {
    return (
        <table className={styles.root__benefits__table_container}>
            <thead>
                <tr className={styles.root__benefits__table_container__th_header}>
                    <th className={styles.root__benefits__table_container__th_header_benefits}>Beneficios</th>
                    <th className={styles.root__benefits__table_container__th_header_segmentation}>Explorador</th>
                    <th className={styles.root__benefits__table_container__th_header_segmentation}>Aprendiz</th>
                    <th className={styles.root__benefits__table_container__th_header_segmentation}>Profesional</th>
                    <th className={styles.root__benefits__table_container__th_header_segmentation}>Maestro</th>
                    <th className={styles.root__benefits__table_container__th_header_segmentation_end}>Leyenda</th>
                </tr>
                <tr>
                    <th className={styles.root__benefits__table_container__th_nav}></th>
                    {IconLevels.map((element, index) => (
                        <th className={styles.root__benefits__table_container__th_nav_elem} key={index}>
                            <Image
                                className={styles.root__benefits__table_container__th_nav_elem__icon}
                                src={element}
                                alt={'Icono Level'}
                                priority
                            />
                        </th>
                    ))}
                </tr>
            </thead>
            <tbody>
                {benefitsData.map((benefit, index) => (
                    <tr className={styles.root__benefits__table_container__tr_body}
                        key={index}>
                        <td className={styles.root__benefits__table_container__tr_body__benefit}>{benefit.benefit}</td>
                        <td className={styles.root__benefits__table_container__tr_body__icon}>{benefit.Explorador ?
                            <Image
                                className={styles.root__benefits__table_container__td__icon}
                                src={IconCheck}
                                alt={'Icono Check'}
                                priority
                            /> : ''}</td>
                        <td className={styles.root__benefits__table_container__tr_body__icon}>{benefit.Aprendiz ?
                            <Image
                                className={styles.root__benefits__table_container__td__icon}
                                src={IconCheck}
                                alt={'Icono Check'}
                                priority
                            /> : ''}</td>
                        <td className={styles.root__benefits__table_container__tr_body__icon}>{benefit.Profesional ?
                            <Image
                                className={styles.root__benefits__table_container__td__icon}
                                src={IconCheck}
                                alt={'Icono Check'}
                                priority
                            /> : ''}</td>
                        <td className={styles.root__benefits__table_container__tr_body__icon}>{benefit.Maestro ?
                            <Image
                                className={styles.root__benefits__table_container__td__icon}
                                src={IconCheck}
                                alt={'Icono Check'}
                                priority
                            /> : ''}</td>
                        <td className={styles.root__benefits__table_container__tr_body__icon}>{benefit.Leyenda ?
                            <Image
                                className={styles.root__benefits__table_container__td__icon}
                                src={IconCheck}
                                alt={'Icono Check'}
                                priority
                            /> : ''}</td>
                    </tr>
                ))}
            </tbody>
        </table>
    );
}

export default BenefitsTableSegmentation
